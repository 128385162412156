.orderpoplb-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  z-index: 999;
  position: fixed;
  right: 0px;
  bottom: 0px;
  transition: all 0.25s ease 0s;
  overflow: hidden;
  background: rgb(15, 23, 32);
  left: 0px;
  visibility: visible;
  transform: translateY(0px);
  border-radius: 0.88rem 0.88rem 0rem 0rem;
  overflow: hidden;
  background: var(--them-color-white-bg);
}

.orderpoplb-1:after {
  /*border-color: rgb(30, 39, 48) !important;*/
}

.orderpoplb-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  padding: 1rem 0.9375rem 1.69rem;
  display: flex;
  align-items: center;
  position: relative;
}


.orderpoplb-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  left: 0px;
  padding: 0px;
}

.orderpoplb-3:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  color: var(--them-color-black-text);
  font-weight: bold;
  font-size: 1.13rem;
}

.orderpoplb-4:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.orderpoplb-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: iconfont2 !important;
  font-style: normal;
  display: flex;
  align-items: center;
  position: absolute;
  top: .5rem;
  right: .5rem;
  padding: .5rem;
  font-size: 1.125rem;
  color: rgb(153, 153, 153);
}

.orderpoplb-6:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-6:before {
  content: "";
}

.orderpoplb-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  width: 100%;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  height: auto;
  min-height: 17.1875rem;
  max-height: 17.1875rem;
}

.orderpoplb-7:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.orderpoplb-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: inherit;
  overflow: hidden auto;
}

.orderpoplb-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
}

.orderpoplb-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: relative;
  display: flex;
  padding: 0 0px 3.6875rem 1.63rem;
}

.orderpoplb-11:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  font-family: sans-serif;
  height: 9.375rem;
  padding: 0;
  width: 3.44rem;
  box-sizing: border-box;
  position: relative;
}

.orderpoplb-12:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  position: absolute;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
  font-family: sans-serif;
}

.orderpoplb-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.orderpoplb-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.orderpoplb-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.orderpoplb-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}

.orderpoplb-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  position: relative;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

.orderpoplb-19 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  flex: 1 1 0%;
  position: relative;
  height: 100%;
  scrollbar-width: none;
  font-family: sans-serif;
}

.orderpoplb-19:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-20 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  height: 100%;
}

.orderpoplb-21 {
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  width: 100%;
  z-index: 3;
  pointer-events: none;
  top: 0px;
  height: 100%;
  margin: 0px auto;
  background-image: linear-gradient(rgba(255, 255, 255, 0.95),
      rgba(255, 255, 255, 0.6)),
    linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6));
  background-position: center top, center bottom;
  background-repeat: no-repeat;
  transform: translateZ(0px);
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  background: transparent !important;
  background-size: 100% 57px;
}

.orderpoplb-21:after {
  border-color: rgb(30, 39, 48) !important;
}

@media (prefers-color-scheme: dark) {
  .orderpoplb-21 {
    background-image: linear-gradient(rgba(35, 35, 35, 0.95),
        rgba(35, 35, 35, 0.6)),
      linear-gradient(0deg, rgba(35, 35, 35, 0.95), rgba(35, 35, 35, 0.6));
  }
}

.orderpoplb-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  width: 80%;
  left: 15%;
  z-index: 3;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  background: rgba(255, 224, 166, 0.3);
  border-radius: 0.1875rem;
  height: 42px;
  display: none;
}

.orderpoplb-22:before {
  top: 0px;
  border-top: 1px solid rgb(229, 229, 229);
  transform-origin: 0px 0px;
  transform: scaleY(0.5);
  content: " ";
  position: absolute;
  left: 0px;
  right: 0px;
  color: rgb(229, 229, 229);
  display: none;
}

@media (prefers-color-scheme: dark) {
  .orderpoplb-22:after {
    border-bottom-color: var(--UI-FG-3);
  }

  .orderpoplb-22:before {
    border-top-color: var(--UI-FG-3);
  }
}

.orderpoplb-23 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
  font-family: sans-serif;
}

.orderpoplb-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.orderpoplb-25 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.orderpoplb-26 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.orderpoplb-27 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}

.orderpoplb-28 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  top: 0px;
  left: 0px;
  width: 100%;
  will-change: transform;
  cursor: pointer;
  padding: 57px 0px;
  transform: translateY(0px) translateZ(0px);
}

.orderpoplb-29 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  height: 1.63rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: .5rem 0;
  padding: 0;
  border-radius: 0.16rem;
}

.orderpoplb-29:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-30 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  text-align: center;
  font-size: 0.9375rem;
  font-style: italic;
  padding: 0px 0.3125rem;
  transition: all 0.25s ease 0s;
}

.orderpoplb-30:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-31 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
}

.orderpoplb-32 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  height: 42px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0.9375rem;
}

.orderpoplb-32:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-33 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  text-align: center;
  font-size: 0.9375rem;
  color: rgb(110, 109, 117);
  font-style: italic;
  padding: 0px 0.3125rem;
  transition: all 0.25s ease 0s;
}

.orderpoplb-33:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-34 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.orderpoplb-35 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  height: 42px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0.9375rem;
}

.orderpoplb-35:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-36 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  text-align: center;
  font-size: 0.9375rem;
  font-style: italic;
  padding: 0px 0.3125rem;
  transition: all 0.25s ease 0s;
}

.orderpoplb-36:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-37 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.orderpoplb-38 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  height: 42px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0.9375rem;
}

.orderpoplb-38:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-39 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  text-align: center;
  font-size: 0.9375rem;
  color: rgb(110, 109, 117);
  font-style: italic;
  padding: 0px 0.3125rem;
  transition: all 0.25s ease 0s;
}

.orderpoplb-39:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-40 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.orderpoplb-41 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  height: 42px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0.9375rem;
}

.orderpoplb-41:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-42 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  text-align: center;
  font-size: 0.9375rem;
  color: rgb(110, 109, 117);
  font-style: italic;
  padding: 0px 0.3125rem;
  transition: all 0.25s ease 0s;
}

.orderpoplb-42:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-43 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.orderpoplb-44 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  height: 42px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0.9375rem;
}

.orderpoplb-44:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-45 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  text-align: center;
  font-size: 0.9375rem;
  color: rgb(110, 109, 117);
  font-style: italic;
  padding: 0px 0.3125rem;
  transition: all 0.25s ease 0s;
}

.orderpoplb-45:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-46 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.orderpoplb-47 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  padding: 0px 0.9375rem;
  flex: 1 1 0%;
}

.orderpoplb-47:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-48 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  gap: .63rem;
  margin-top: 2.34rem;
}

.orderpoplb-48:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-49 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  justify-content: space-between;
}

.orderpoplb-49:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-50 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  color: rgb(153, 153, 153);
  display: block;
}

.orderpoplb-50:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-51 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  color: rgb(243, 100, 100);
}

.orderpoplb-51:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-52 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.orderpoplb-53 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  justify-content: space-between;
}

.orderpoplb-53:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-54 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  color: rgb(153, 153, 153);
  display: block;
}

.orderpoplb-54:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-55 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  color: rgb(64, 145, 151);
}

.orderpoplb-55:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-56 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.orderpoplb-57 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 0.875rem;
  color: rgb(136, 136, 136);
  display: block;
  margin-top: 0.9375rem;
}

.orderpoplb-57:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-58 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-height: 1.4em;
  overflow: hidden;
  scrollbar-width: none;
  font-family: sans-serif;
  text-align: right;
  font-size: 0.875rem;
  height: 2.5rem;
  border-radius: 0.3125rem;
  line-height: 2.8125rem;
  padding: 0px 0.625rem;
  height: 2.56rem;
  background: var(--them-color-gray-bg);
  border-radius: 0.38rem;
}

.orderpoplb-58:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-59 {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.orderpoplb-60 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  width: 100%;
  position: absolute;
  left: 0px;
  color: gray;
  overflow: hidden;
  text-overflow: clip;
  white-space: pre;
  word-break: keep-all;
  pointer-events: none;
  line-height: inherit;
  top: auto !important;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.orderpoplb-60:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-61 {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  width: 100%;
  position: relative;
  display: block;
  height: 100%;
  background: none;
  color: inherit;
  opacity: 1;
  font: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-indent: inherit;
  text-transform: inherit;
  text-shadow: inherit;
}

.orderpoplb-62 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  margin-top: 0.62rem;
  gap: .4rem;
}

.orderpoplb-62:after {
  border-color: rgb(30, 39, 48) !important;
}

.orderpoplb-63 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(248, 248, 248);
  cursor: pointer;
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  height: 2.1875rem;
  flex: 1 1 0%;
}

.orderpoplb-64 {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  height: 2.1875rem;
  flex: 1 1 0%;
}

.orderpoplb-65 {
  margin-right: auto;
  padding-left: 14px;
  padding-right: 14px;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  background-color: rgb(248, 248, 248);
  cursor: pointer;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  overflow: hidden;
  position: relative;
  border: 0px;
  outline: 0px;
  padding: 0px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  height: 2.1875rem;
  flex: 1 1 0%;
  color: rgba(255, 255, 255, 0.5);
  background: rgb(22, 30, 38);
  margin-left: 0.3125rem;
}

.orderpoplb-65:after {
  width: 200%;
  top: 0px;
  left: 0px;
  transform: scale(0.5);
  transform-origin: 0px 0px;
  display: none;
  content: "";
  position: absolute;
  inset: 0px;
  border: 0px;
  pointer-events: none;
  border-color: rgb(30, 39, 48) !important;
}

@media (prefers-color-scheme: dark) {
  .orderpoplb-65 {
    color: rgb(214, 214, 214);
    background-color: rgb(52, 52, 52);
  }
}

.orderpoplb-66 {
  margin-right: auto;
  padding-left: 14px;
  padding-right: 14px;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  background-color: rgb(248, 248, 248);
  cursor: pointer;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  overflow: hidden;
  position: relative;
  border: 0px;
  outline: 0px;
  padding: 0px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  height: 2.1875rem;
  flex: 1 1 0%;
  color: rgba(255, 255, 255, 0.5);
  background: rgb(22, 30, 38);
  margin-left: 0.3125rem;
}

.orderpoplb-66:after {
  width: 200%;
  top: 0px;
  left: 0px;
  transform: scale(0.5);
  transform-origin: 0px 0px;
  display: none;
  content: "";
  position: absolute;
  inset: 0px;
  border: 0px;
  pointer-events: none;
  border-color: rgb(30, 39, 48) !important;
}

@media (prefers-color-scheme: dark) {
  .orderpoplb-66 {
    color: rgb(214, 214, 214);
    background-color: rgb(52, 52, 52);
  }
}

.orderpoplb-67 {
  margin-left: auto;
  margin-right: auto;
  padding-left: 14px;
  padding-right: 14px;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  background-color: rgb(248, 248, 248);
  cursor: pointer;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  overflow: hidden;
  border: 0px;
  outline: 0px;
  padding: 0px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  position: absolute;
  bottom: 10px;
  left: 0.9375rem;
  right: 0.9375rem;
  height: 2.375rem;
  background: rgb(64, 145, 151);
  color: rgb(255, 255, 255);
  font-size: 0.875rem;
  border-radius: 0.3125rem;
}


.orderpoplb-67-1 {
  margin-left: auto;
  margin-right: auto;
  padding-left: 14px;
  padding-right: 14px;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  background-color: rgb(248, 248, 248);
  cursor: pointer;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  overflow: hidden;
  border: 0px;
  outline: 0px;
  padding: 0px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  position: absolute;
  bottom: 10px;
  left: 0.9375rem;
  right: 0.9375rem;
  height: 2.375rem;
  background: rgb(243, 100, 100);
  color: rgb(255, 255, 255);
  font-size: 0.875rem;
  border-radius: 0.3125rem;
}

.orderpoplb-67:after {
  width: 200%;
  top: 0px;
  transform: scale(0.5);
  transform-origin: 0px 0px;
  display: none;
  content: "";
  inset: 0px;
  border: 0px;
  pointer-events: none;
  border-color: rgb(30, 39, 48) !important;
}

/*@media (prefers-color-scheme: dark) {*/
/*  .orderpoplb-67 {*/
/*    color: rgb(214, 214, 214);*/
/*    background-color: rgb(52, 52, 52);*/
/*  }*/
/*}*/

.orderpoplb-act {
  background: var(--them-color-green-text);
  color: var(--them-color-white-bg);
}
.orderpoplb-item {
  flex: 1;
  background: var(--them-color-gray-bg);
  color: var(--them-color-gray-text);
}
.orderpoplb-item-act {
  background: var(--them-color-green-text);
  color: var(--them-color-white-bg);
}